import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from './store'
import ConfigProvider from "ant-design-vue/es/config-provider";
import Button from "ant-design-vue/es/button";
import message from "ant-design-vue/es/message";
import Layout from "ant-design-vue/es/layout";
import Modal from "ant-design-vue/es/modal";
import Form from "ant-design-vue/es/form";
import Input from "ant-design-vue/es/input";
import Checkbox from "ant-design-vue/es/checkbox";
import Divider from "ant-design-vue/es/divider";
import Menu from "ant-design-vue/es/menu";
import Card from "ant-design-vue/es/card";
import Table from "ant-design-vue/es/table";
import Select from "ant-design-vue/es/select";
import Spin from "ant-design-vue/es/spin";
import Avatar from "ant-design-vue/es/avatar";
import Switch from "ant-design-vue/es/switch";
import Typography from "ant-design-vue/es/typography";
import Dropdown from "ant-design-vue/es/dropdown";
import Popconfirm from "ant-design-vue/es/popconfirm"
import Tooltip from "ant-design-vue/es/tooltip"
import Alert from "ant-design-vue/es/alert"
import Popover from "ant-design-vue/es/popover"
import "@/assets/style.scss";
import "@/assets/style.less";
// setup fake backend
// import { configureFakeBackend } from './_utils/fake-backend';
// configureFakeBackend();

const antd = [ConfigProvider, Button, Layout, Modal, Form, Input, Checkbox, Divider, Menu, Card, Table, Select, Spin, Avatar, Switch, Typography, Dropdown, Popconfirm, Tooltip, Alert, Popover];

const app = createApp(App).use(store, key).use(router);

app.config.globalProperties.$message = message;
app.config.globalProperties.$confirm = Modal.confirm;

antd.forEach((component) => app.use(component));

app.mount("#app");
