import { api } from "../_modules/api";
import { IMemberAccount, IPageResponse, IPermission, IPermissionPayload, IResponse
 } from "@/_utils/types";

const url = '/api-platform/v1'

interface IQuery {
    page?: number;
    size?: number;
    name?: string;
    account_id?: number;
}

const getAccounts = async (query?: IQuery): Promise<IResponse<IPageResponse<IMemberAccount[]>>> => {
    const { data: res } = await api.get(`${url}/member-managements/feature/big-data`, { ...(query && { params: query }) })
    const result: IResponse<IPageResponse<IMemberAccount[]>> = {
        ...res,
        data: res.data.accounts
    }
    return result
}

const getPermissions = async (query?: IQuery): Promise<IResponse<IPageResponse<IPermission[]>>> => {
    const { data: res } = await api.get(`${url}/feature/big-data/permissions`, { ...(query && { params: query }) })
    const result: IResponse<IPageResponse<IPermission[]>> = {
        ...res,
        data: res.data.permission
    }
    return result
}

const updatePermissionAccount = async (accountId: number, permissionId: number): Promise<IResponse<null>> => {
    const { data: res } = await api.post(`${url}/member-managements/feature/big-data/account/${accountId}/update-permission`, {
        permissions: permissionId
    })
    return res
}

const resendEmail = async (accountId: number): Promise<IResponse<any>> => {
    const { data: res } = await api.post(`${url}/member-managements/feature/big-data/account/${accountId}/resend-email`)
    return res
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createPermission = async (payload: IPermissionPayload): Promise<IResponse<any>> => {
    const { data } = await api.post(`${url}/feature/big-data/permissions`,  payload)
    return data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updatePermission = async (permissionId: number, payload: IPermissionPayload): Promise<IResponse<any>> => {
    const { data } = await api.post(`${url}/feature/big-data/permissions/${permissionId}/update`,  payload)
    return data
}

export const bigDataService = {
    getAccounts,
    getPermissions,
    updatePermissionAccount,
    resendEmail,
    updatePermission,
    createPermission
}
