<template>
	<a-modal
	:width="width"
	centered
	:visible="visible"
	:footer="null"
	:closable="false"
	>
	<slot />
	<p v-if="visibleFooter" class="text-center"><a-button class="px-5" type="primary" @click="$emit('closeAlertModal')">OK</a-button></p>
</a-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		visible: Boolean,
		width: {
			type: Number,
			required: false,
			default: 300
		},
		visibleFooter: {
			type: Boolean,
			default: true,
			required: false
		}
	}
})
</script>

