import { api } from "../_modules/api";
import { IMemberAccount, IPageResponse, IPermission, IPermissionPayload, IResponse } from "@/_utils/types";

const url = '/api-platform/v1'

interface IQuery {
    page?: number;
    size?: number;
    name?: string;
    account_id?: number;
}

const getAccounts = async (query?: IQuery): Promise<IResponse<IPageResponse<IMemberAccount[]>>> => {
    const { data: res } = await api.get(`${url}/member-managements/feature/kpi`, { ...(query && { params: query }) })
    const result: IResponse<IPageResponse<IMemberAccount[]>> = {
        ...res,
        data: res.data.accounts
    }
    return result
}

const getPermissions = async (query?: IQuery): Promise<IResponse<IPageResponse<IPermission[]>>> => {
    const { data: res } = await api.get(`${url}/feature/kpi/permissions`, { ...(query && { params: query }) })
    const result: IResponse<IPageResponse<IPermission[]>> = {
        ...res,
        data: res.data.permission
    }
    return result
}

const updatePermissionAccount = async (accountId: number, permissionId: number): Promise<IResponse<null>> => {
    const { data: res } = await api.post(`${url}/member-managements/feature/kpi/account/${accountId}/update-permission`, {
        permissions: permissionId
    })
    return res
}

const resendEmail = async (accountId: number): Promise<IResponse<null>> => {
    const { data: res } = await api.post(`${url}/member-managements/feature/kpi/account/${accountId}/resend-email`)
    return res
}

const createPermission = async (payload: IPermissionPayload): Promise<IResponse<any>> => {
    const { data } = await api.post(`${url}/feature/kpi/permissions`,  payload)
    return data
}

const updatePermission = async (permissionId: number, payload: IPermissionPayload): Promise<IResponse<any>> => {
    const { data } = await api.post(`${url}/feature/kpi/permissions/${permissionId}/update`,  payload)
    return data
}

export const reportService = {
    getAccounts,
    getPermissions,
    updatePermissionAccount,
    resendEmail,
    updatePermission,
    createPermission
}
