
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		visible: Boolean,
		width: {
			type: Number,
			required: false,
			default: 300
		},
		visibleFooter: {
			type: Boolean,
			default: true,
			required: false
		}
	}
})
