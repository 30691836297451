import { api } from "../_modules/api";
import { IResponse, IUpdatePwdDto, IUser } from "@/_utils/types";

const url = '/api-platform/v1/account';

const getRolePermission = async (): Promise<IResponse<IUser>> => {
  const { data } = await api.get(`${url}/roles-permissions`);
  return data;
};
const updatePassword = async (dto: IUpdatePwdDto): Promise<IResponse<any>> => {
  const { data } = await api.post(`${url}/reset-password`, { ...dto });
  return data;
};

export const accountService = {
  getRolePermission,
  updatePassword
};
