export * from './auth.service'
export * from './account.service'
export * from './big-data.service'
export * from './academy.service'
export * from './e-learning.service'
export * from './report.service'

import { api } from "../_modules/api";
import { IResponse, IFeature } from "@/_utils/types";



const getFeatures = async (): Promise<IResponse<IFeature>> => {
    const { data } = await api.get('/api-platform/v1/feature')
    return data
}

export const appService = {
    getFeatures
}