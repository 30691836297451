import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
// import { computed, ComputedRef, ref, watch, Ref } from 'vue'
// import { useRouter } from 'vue-router'
import { AUTH_KEY } from './auth'
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
// import { createVNode } from 'vue';
// import { Modal, ConfigProvider } from 'ant-design-vue';
import { notification } from 'ant-design-vue';


const api = axios.create({
	// baseURL: process.env.VUE_APP_API || 'http://localhost:3000/'
	baseURL: '/'
})
api.interceptors.request.use(
	function (config: AxiosRequestConfig) {
		// Do something before request is sent
		// Read access token from local storage?
		const token = window.localStorage.getItem(AUTH_KEY)
		if(token){
			// api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			config.headers.common['Authorization'] = `Bearer ${token}`;
		}
		return config;
    },
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

api.interceptors.response.use(function (response: AxiosResponse) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error: AxiosError) {
	const { response } = error
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const errorObj: any = error.toJSON()
	if(response) {
        const { data, status } = response
		if(status >= 500) {
			notification.error({
				message: 'Something went wrong!',
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				description: `${errorObj.message!}`,
			});
		} /* else if(status === 401) {
			window.localStorage.removeItem(AUTH_KEY)
			return location.reload()
		} */

		return Promise.reject(data)
	}
	return Promise.reject(errorObj) //Promise.reject(error);
});

export { api }

